<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api
        ref="tableSupply"
        :api="api"
        :model="m.list"
        :searchable="false"
        :defaults="{
          filters,
          sort: { key: 'id', order: 'DESC' },
          paramName: 'tabSupply',
        }"
        @click="onClickReportRow($event)"
      >
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu],
  components: {},
  props: {
    id: Number,
    data: Object,
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.supply,
    };
  },
  created() {},
  watch: {
    showReportDialog() {
      if (!this.showReportDialog) {
        this.$refs.tableReports.updateData();
      }
    },
  },
  computed: {
    filters() {
      let f = { shop_id: this.id };
      return f;
    },
    api() {
      return this.m.api;
    },
  },
  methods: {},
};
</script>

<style lang="sass"></style>
